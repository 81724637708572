import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Question, Quiz, QuizAnswer, QuizKey } from 'types/quiz';
import { DynamicImage, PrimaryButton, Text } from 'components';
import { tablet } from 'styles/breakpoints';

import LCFooter from 'components/LCFooter';
import { useScrollToTop } from 'utils/hooks';

import Header from 'components/reviews/Header';
import PRFooter from 'components/PRFooter';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-top: 1rem;
  @media ${tablet} {
    font-size: 2rem;
    padding-top: unset;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 2rem 0 1rem;
  span {
    font-weight: 700;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem 2.25rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  @media ${tablet} {
    flex-direction: column-reverse;
  }
`;

const Button = styled(PrimaryButton)<{
  inverted?: boolean;
  active?: boolean;
  animate: boolean;
}>`
  ${({ inverted }) => inverted && 'background: #000;'}
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
`;

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const [active, setActive] = useState<string | null>(null);
  const [animateButton, setAnimateButton] = useState<string | null>(null);

  const handleMouseDown = (buttonValue: string) => {
    setAnimateButton(buttonValue); // Animate the specific button
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setAnimateButton(null); // Reset the animation after a brief delay
    }, 100);
  };

  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;
  const isAddsFlow = quiz !== Quiz.Main;

  useScrollToTop(0);

  const handleOptionClick = (value: string, label: string) => {
    setActive(value);
    setTimeout(
      () =>
        onOptionSelect({
          [question?.key]: value,
          label: label,
        }),
      0,
    );
  };

  return (
    <>
      <Header isMobileApp={isAddsFlow} />
      <Outer isFullHeight={isAddsFlow}>
        <ContentContainer>
          <DynamicImage src={question.image} alt="" />
          <InnerContainer>
            <Title dangerouslySetInnerHTML={{ __html: question?.label }} />
            <Subtitle
              dangerouslySetInnerHTML={{ __html: question?.optionsLabel }}
            />
            <ButtonsContainer>
              {question?.options?.map((option, index) => (
                <Button
                  onMouseDown={() => handleMouseDown(option.value)}
                  onMouseUp={handleMouseUp}
                  animate={animateButton === option.value} // Only animate the active button
                  inverted={option?.inverted}
                  key={index}
                  onClick={() =>
                    handleOptionClick(option?.value, option?.label)
                  }
                  active={option.value === active}
                >
                  {option.label}
                </Button>
              ))}
            </ButtonsContainer>
          </InnerContainer>
        </ContentContainer>
      </Outer>
      {isAddsFlow ? (
        <PRFooter disclaimerText={question?.disclaimerParagraphsPR} />
      ) : (
        <LCFooter disclaimerText={question?.disclaimerParagraphs} />
      )}
    </>
  );
};

export default Landing;

const Outer = styled.section<{ isFullHeight: boolean }>`
  background: #fff;
  ${({ isFullHeight }) => isFullHeight && 'min-height: calc(100vh - 4.5rem);'}
  @media ${tablet} {
    ${({ isFullHeight }) => isFullHeight && 'min-height: calc(100vh - 4rem);'}
  }
`;

const ContentContainer = styled.div`
  min-height: calc(100vh - 225px);
  max-width: 34.5rem;
  width: 100%;
  margin: 0 auto;

  @media ${tablet} {
  }
`;
