import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import styled from 'styled-components';

interface ProgressCountProps {
  total: number;
  count: number;
}

const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => (
  <TextStyled>
    {count}/{total}
  </TextStyled>
);

export default ProgressCount;

const TextStyled = styled(Text)`
  color: #000;
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
