import React, { ComponentPropsWithoutRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import Arrow from 'assets/icons/quiz/arrow.svg';
import Single from 'assets/icons/quiz-arrow.svg';
import { DynamicImage } from './images/DynamicImage';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  isMultiple?: boolean;
  smallEmoji?: boolean;
  emoji?: string;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  animate: boolean;
}>`
  background: ${({ active }) => (active ? '#FCEDED' : '#F7F7F7')};
  border: 1px solid
    ${({ active }) => (active ? '#DC4B4B' : 'rgba(0, 0, 0, 0.08)')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  height: 4rem;

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};

  border-radius: 0.625rem;
  padding: 1rem 0.75rem;
  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
`;

const ButtonText = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 1rem;
`;

const Inner = styled.section`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  isMultiple,
  emoji,
  smallEmoji,
  ...props
}) => {
  const [animate, setAnimate] = useState(false);

  const handleMouseDown = () => {
    setAnimate(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setAnimate(false);
    }, 100);
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <Container
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      active={active}
      animate={animate}
      maxSelected={maxSelected}
      as="button"
      {...props}
    >
      <Inner>
        {isMultiple && (
          <MultipleContainer active={active as boolean}>
            <MultipleArrow active={active as boolean} />
          </MultipleContainer>
        )}
        <ButtonText> {children}</ButtonText>
      </Inner>
      {emoji && (
        <EmojiContainer isSmall={smallEmoji}>
          <Emoji src={emoji} alt="emoji" isSmall={smallEmoji} />
        </EmojiContainer>
      )}
      {!isMultiple && !emoji && <Single />}
    </Container>
  );
};
3;

export default React.memo(QuizAnswerButton);

const EmojiContainer = styled.section<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
  height: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
`;

const Emoji = styled(DynamicImage)<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
  height: ${({ isSmall }) => (isSmall ? '1.5rem' : '2.5rem')};
`;

const MultipleArrow = styled(Arrow)<{ active: boolean }>`
  transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0.7)')};
  transition: transform 0.3s ease-out;
  path {
    stroke: ${({ active }) => (active ? '#fff' : '#F7F7F7')};
  }
`;

const MultipleContainer = styled.section<{ active: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;

  background: ${({ active }) => (active ? '#DC4B4B' : '#F7F7F7')};
  border: 1.2px solid ${({ active }) => (active ? '#DC4B4B' : '#000')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
