import type { DefaultTheme } from 'styled-components';

export const FONT_FAMILY: string = 'Figtree';

const typography: DefaultTheme['typography'] = {
  h1: {
    fontFamily: FONT_FAMILY,
    fontSize: 2,
    lineHeight: 2.8,
    fontWeight: 600,
  },
  h1400: {
    fontFamily: FONT_FAMILY,
    fontSize: 2,
    lineHeight: 2.6,
    fontWeight: 400,
  },
  h1700: {
    fontFamily: FONT_FAMILY,
    fontSize: 2,
    lineHeight: 2.6,
    fontWeight: 700,
  },
  h13Rem: {
    fontFamily: FONT_FAMILY,
    fontSize: 3,
    lineHeight: 3.9,
    fontWeight: 600,
  },
  h13Rem400: {
    fontFamily: FONT_FAMILY,
    fontSize: 3,
    lineHeight: 3.9,
    fontWeight: 400,
  },
  h12375Rem700: {
    fontFamily: FONT_FAMILY,
    fontSize: 2.375,
    lineHeight: 3.2,
    fontWeight: 700,
  },
  count: {
    fontFamily: FONT_FAMILY,
    fontSize: 2.37,
    lineHeight: 3.09,
    fontWeight: 600,
  },
  countS: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.6,
    lineHeight: 2.27,
    fontWeight: 600,
  },
  h2: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.5,
    lineHeight: 2.1,
    fontWeight: 700,
  },
  h21625Rem: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.625,
    lineHeight: 2,
    fontWeight: 700,
  },
  h2400: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.5,
    lineHeight: 2.1,
    fontWeight: 400,
  },
  h2600: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.5,
    lineHeight: 2.1,
    fontWeight: 600,
  },
  h3: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.25,
    lineHeight: 1.75,
    fontWeight: 600,
  },
  h3700: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.25,
    lineHeight: 1.75,
    fontWeight: 700,
  },
  h3400: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.25,
    lineHeight: 1.75,
    fontWeight: 400,
  },
  h3500: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.25,
    lineHeight: 1.75,
    fontWeight: 500,
  },
  h4: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.125,
    lineHeight: 1.4625,
    fontWeight: 600,
  },
  h4400: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.125,
    lineHeight: 1.575,
    fontWeight: 400,
  },
  h4700: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.125,
    lineHeight: 1.575,
    fontWeight: 700,
  },
  h4800: {
    fontFamily: FONT_FAMILY,
    fontSize: 1.125,
    lineHeight: 1.575,
    fontWeight: 800,
  },
  body: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1.4,
    fontWeight: 400,
  },
  body500: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1.4,
    fontWeight: 500,
  },
  body600: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1.4,
    fontWeight: 600,
  },
  body700: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: '130%',
    fontWeight: 700,
  },
  bodyM: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.875,
    lineHeight: 1.225,
    fontWeight: 400,
  },
  bodyM600: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.875,
    lineHeight: 1.225,
    fontWeight: 600,
  },
  bodyM700: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.875,
    lineHeight: '140%',
    fontWeight: 700,
  },
  bodyS: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.75,
    lineHeight: 1.05,
    fontWeight: 400,
  },
  bodyS0625: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.625,
    lineHeight: 1.05,
    fontWeight: 400,
  },
  bodyS06254: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.625,
    lineHeight: 1.05,
    fontWeight: 400,
  },
  bodyXS: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.5,
    lineHeight: 0.7,
    fontWeight: 400,
  },
  bodyS500: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.75,
    lineHeight: 1.05,
    fontWeight: 500,
  },
  bodyS600: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.75,
    lineHeight: 1.05,
    fontWeight: 600,
  },
  bodyS700: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.75,
    lineHeight: '150%',
    fontWeight: 700,
  },
  caption: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1.4,
    fontWeight: 400,
  },
  captionM: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.875,
    lineHeight: 1.225,
    fontWeight: 400,
  },
  captionS: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.75,
    lineHeight: 1.05,
    fontWeight: 400,
  },
  tab: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1.4,
    fontWeight: 400,
  },
  tabActive: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1.4,
    fontWeight: 600,
  },
  tabNavigation: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.75,
    lineHeight: 1.05,
    fontWeight: 400,
  },
  tabNavigationActive: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.75,
    lineHeight: 1.05,
    fontWeight: 600,
  },
  button: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 500,
  },
  buttonText: {
    fontFamily: FONT_FAMILY,
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 500,
  },
  buttonTextM: {
    fontFamily: FONT_FAMILY,
    fontSize: 0.875,
    lineHeight: 0.875,
    fontWeight: 500,
  },
};

const colors: DefaultTheme['colors'] = {
  dark100: '#1C1C28',
  dark80: '#28293D',
  dark60: '#555770',
  dark40: '#8F90A6',
  dark20: '#C7C9D9',
  light100: '#E4E4EB',
  light80: '#EBEBF0',
  light60: '#F2F2F5',
  light40: '#FAFAFC',
  light0: '#FFFFFF',
  red110: '#FB513C',
  red100: '#E53535',
  red80: '#FF3B3B',
  red60: '#FF5C5C',
  red40: '#FF8080',
  red20: '#FFE5E5',
  greenDark: '#048F53',
  green100: '#05A660',
  green80: '#06C270',
  green60: '#39D98A',
  green40: '#57EBA1',
  green20: '#E3FFF1',
  greenLight: '#D9FCE4',
  blue100: '#004FC4',
  blue80: '#0063F7',
  blue60: '#5B8DEF',
  blue40: '#9DBFF9',
  blue20: '#E5F0FF',
  yellow100: '#E5B800',
  yellow80: '#FFCC00',
  yellow60: '#FDDD48',
  yellow40: '#FDED72',
  yellow20: '#FFFEE5',
  orange100: '#E57A00',
  orange80: '#FF8800',
  orange60: '#FDAC42',
  orange40: '#FCCC75',
  orange20: '#FFF8E5',
  teal100: '#00B7C4',
  teal80: '#00CFDE',
  teal60: '#73DFE7',
  teal40: '#A9EFF2',
  teal20: '#E5FFFF',
  purple100: '#4D0099',
  purple80: '#6600CC',
  purple60: '#AC5DD9',
  purple40: '#DDA5E9',
  purple20: '#FFE5FF',
  ochreLight: '#FEE4B9',
  ochreLightLight: '#FCF5EB',
  lightGreen: '#E9F6F2',
  green: '#2FBE8F',
  purple: '#C78CB8',
  darkPurple: '#C1C1F2',
  lightPurple: '#ECECFF',
  cultured: '#F7F9F8',
  scale1: '#EF4F4F',
  scale2: '#EF6969',
  scale3: '#F0C61F',
  scale4: '#4ED190',
  scale5: '#24BE7A',
  red: '#FF3939',
  vividTangerine: '#FF948C',
  paginationBulletBg: '#FCFFFE',
  darkBlue: '#001ECD',
  paypalBackground: '#ffc439',
  lightRed: '#FFD1D1',
  venetianRed: '#EB4C49',
  unset: 'unset',
  orange: '#FD4D32',
  primary: '#DC4B4B',
  primaryLight: '#F9B3B0',
};

const shadows: DefaultTheme['shadows'] = {
  card: '0rem 0.03rem 0.125rem 0rem rgba(96, 97, 112, 0.16), 0rem 0rem 0.06rem 0rem rgba(40, 41, 61, 0.08)',
  cardRaised: '0 0.25rem 2rem rgba(28, 28, 40, 0.06)',
  button:
    '0rem 0.125rem 0.25rem 0rem rgba(96, 97, 112, 0.16), 0rem 0rem 0.06rem 0rem rgba(40, 41, 61, 0.04)',
  buttonPressed:
    '0rem 0.5rem 1rem 0rem rgba(96, 97, 112, 0.16), 0rem 0.125rem 0.25rem 0rem rgba(40, 41, 61, 0.04)',
};

const theme: DefaultTheme = {
  typography,
  colors,
  shadows,
};

export default theme;

export type Color = keyof DefaultTheme['colors'];

export type Typography = keyof DefaultTheme['typography'];

export type Shadow = keyof DefaultTheme['shadows'];
